<template>
    <transition name="fade">
        <div class="shared-map-side-panel">
            <div
                class="l-inline l-center-v l-spread l-padded-x u-bb shared-map-side-panel__navigation"
            >
                <span />

                <div>
                    {{ $t('router.detail') }}
                </div>

                <IconButton @click="$emit('close')">
                    <RemoveIcon width="24" height="24" />
                </IconButton>
            </div>

            <div>
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import IconButton from './IconButton'
import RemoveIcon from './icons/RemoveIcon'

export default {
    name: 'SharedMapSidePanel',
    components: {
        IconButton,
        RemoveIcon,
    },
}
</script>

<style lang="scss" scss>
.shared-map-side-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: -1px 0 1px rgba(0, 0, 0, 0.04),
        -3px 20px 80px rgba(0, 0, 0, 0.3);
    pointer-events: auto;

    &__navigation {
        flex: 0 0 64px;

        & > span {
            &:first-child {
                width: 24px;
            }
        }

        & + * {
            flex: 1;
            overflow-y: auto;
        }
    }
}

.fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.2s;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
