var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shared-layout"},[_c('div',{staticClass:"shared-layout__controls"},[_c('MapControls',{attrs:{"disabled-controls":{
                accuracy: true,
                locations: true,
            }}})],1),_c('div',{staticClass:"shared-layout__panel"},[(_vm.$route.name === 'sharedAssetDetails')?_c('SharedMapSidePanel',{on:{"close":_vm.handleSidePanelClose}},[_c('router-view')],1):_vm._e()],1),_c('LMap',{ref:"map",staticClass:"shared-layout__map",attrs:{"options":{
            attributionControl: false,
            preferCanvas: true,
            zoomControl: false,
        },"max-bounds":[
            [-90, -270],
            [90, 270] ],"max-bounds-viscosity":0.75,"max-zoom":_vm.activeLayer.maxZoom || _vm.baseTileOptions.maxZoom,"min-zoom":_vm.activeLayer.minZoom || _vm.baseTileOptions.minZoom},on:{"locationfound":_vm.onLocationFound,"locationerror":_vm.onLocationError}},[_c('TrackerLayer',{attrs:{"trackers":_vm.trackersToDisplay},on:{"alignMap":_vm.alignMap,"markerClicked":_vm.handleTrackerClick}}),_c('TrackerTraceLayer',{attrs:{"coordinates":_vm.activeSharedTrackerTrace}}),_c('UserLayer'),_c('LControlAttribution',{attrs:{"position":"bottomright","prefix":_vm.appVersion}}),_c('LControlScale',{attrs:{"position":"bottomleft","imperial":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }